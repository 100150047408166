import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import Embed from "../components/blocks/embed"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import Video from "../components/blocks/video"
import RichContentCollection from "../components/blocks/richContentCollection"
import FormEmbed from "../components/blocks/formEmbed"
import CsvTable from "../components/blocks/csvTable"
import BannerCollection from "../components/blocks/bannerCollection"
import PartnerCollection from "../components/blocks/partnerCollection"
import StoriesCollection from "../components/blocks/storiesCollection"
import WebinarCollection from "../components/blocks/webinarCollection"
import ExternalLinkHighlight from "../components/blocks/externalLinkHighlight"
import RestrictedDocuments from "../components/blocks/restrictedDocuments"
import FilterMetaTagDescription from "../utils/filterMetaTags"
import RichContentStructuredText from "../components/richContentStructuredText"
import PageGrid from "../components/blocks/pageGrid"
import BlogTitle from "../components/blogTitle"
import StoriesCustomCollection from "../components/blocks/storiesCustomCollection"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({ data: { page }, location }) => {
  // console.log(page)

  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} location={location}>
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero
        page={page}
        image={page.heroImage ? page.heroImage.header : null}
      />
      <Container>
        <RichContentStructuredText text={page.body} />
      </Container>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "title_and_body" && (
            <Container variant="text">
              <TitleAndBody
                title={block.content.title}
                label={block.content.label}
                subtitle={block.content.subtitle}
                body={block.content.body}
              />
            </Container>
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.content.title}
              subtitle={block.content.subtitle}
              body={block.content.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel
              id={block.id}
              title={block.title}
              pages={block.pages}
              backgroundColor={block.backgroundColor.hex}
            />
          )}
          {block.model.apiKey === "page_grid" && (
            <PageGrid id={block.id} title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} id={block.id} />
          )}
          {block.model.apiKey === "rich_content_collection" && (
            <RichContentCollection
              title={block.title}
              subtitle={block.subtitle}
              items={block.items}
              id={block.id}
            />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "document_collection" && (
            <>
              {block.restricted ? (
                <RestrictedDocuments
                  title={block.title}
                  documents={block.documents}
                  showPublicationDate={block.showPublicationDate}
                />
              ) : (
                <DocumentCollection
                  title={block.title}
                  documents={block.documents}
                  showPublicationDate={block.showPublicationDate}
                />
              )}
            </>
          )}
          {block.model.apiKey === "webinar_collection" && (
            <WebinarCollection title={block.title} webinars={block.webinars} />
          )}
          {block.model.apiKey === "image" && <Image image={block.image} />}
          {block.model.apiKey === "locations_map" && (
            <LocationsMap locations={block.locations} />
          )}
          {block.model.apiKey === "embed" && (
            <Embed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "image_gallery" && (
            <ImageGallery
              images={block.images}
              fullWidth={block.fullWidth}
              id={block.id}
            />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "video" && (
            <Video
              title={block.title}
              embedCode={block.embedCode}
              customHtml={block.customHtml}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.content.image}
              rightAligned={block.rightAligned}
              addMarginBottom={block.addMarginBottom}
            />
          )}
          {block.model.apiKey === "form_embed" && (
            <FormEmbed
              title={block.title}
              subtitle={block.subtitle}
              label={block.label}
              alternate={block.alternate}
              dataFormBlockId={block.form.dataFormBlockId}
              dataWebsiteId={block.form.dataWebsiteId}
              dataHostname={block.form.dataHostname}
              divId={block.form.divId}
              id={block.id}
            />
          )}
          {block.model.apiKey === "csv_table" && (
            <Container variant="text">
              <CsvTable
                title={block.title}
                url={block.csv.url}
                legend={block.legend}
              />
            </Container>
          )}
          {block.model.apiKey === "stories_collection" && (
            <StoriesCollection id={block.category.id} />
          )}
          {block.model.apiKey === "stories_custom_collection" && (
            <StoriesCustomCollection stories={block.stories} />
          )}
          {block.model.apiKey === "banner_collection" && (
            <BannerCollection banners={block.banners} />
          )}
          {block.model.apiKey === "partner_collection" && (
            <Container>
              <PartnerCollection partners={block.partners} />
            </Container>
          )}
          {block.model.apiKey === "external_link_highlight" && (
            <ExternalLinkHighlight link={block.link} />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsExternalLinkHighlight {
          id
          link {
            id
            anchor
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsWebinarCollection {
          id
          title
          webinars {
            title
            language
            country
            date(locale: $locale, formatString: "DD MMMM Y")
            url
            category {
              name
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesCollection {
          id
          category {
            id
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStoriesCustomCollection {
          id
          model {
            apiKey
          }
          stories {
            ...StoryDetails
            ...StoryAllSlugLocales
          }
        }
        ... on DatoCmsPartnerCollection {
          id
          partners {
            id
            name
            url
            image {
              gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBannerCollection {
          id
          banners {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCsvTable {
          id
          title
          legend
          csv {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFormEmbed {
          id
          title
          subtitle
          label
          alternate
          form {
            ...DynamicsFormDetails
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          id
          title
          embedCode
          customHtml
          addMarginBottom
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          addMarginBottom
          image {
            gatsbyImageData(
              width: 740
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }

        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          rightAligned
          addMarginBottom
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            name
            body
            coordinates {
              latitude
              longitude
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          restricted
          documents {
            title
            subtitle
            category {
              name
            }
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          backgroundColor {
            hex
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageGrid {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
              meta {
                firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsRichContentCollection {
          id
          title
          subtitle
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment DynamicsFormDetails on DatoCmsDynamicsForm {
    dataFormBlockId
    dataWebsiteId
    dataHostname
    divId
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      url
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsExternalLink {
          id: originalId
          anchor
          url
        }
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsEventPage {
              ...EventPageDetails
            }
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
      value
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    model {
      apiKey
    }
    heroImage {
      header: gatsbyImageData(
        width: 1920
        placeholder: BLURRED
        forceBlurhash: false
      )
      list: gatsbyImageData(
        width: 600
        height: 300
        placeholder: BLURRED
        forceBlurhash: false
      )
    }
    pageThumb {
      gatsbyImageData(width: 720, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      value
      blocks {
        __typename
        ... on DatoCmsFaqList {
          id: originalId
          faqList {
            id
            answer
            question
          }
        }
      }
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
